import settingServices from '@/modules/settings/services/settings';
export default {
  data () {
    return {
      vendorInfo: {},
      storeActive: {},
      myStores: [],
      sourceObj: {},
      market: {},
    };
  },
  created () {
    this.getInfo();
  },

  methods: {
    async getInfo () {
      const { data } = await settingServices.getMerchantInfo();
      this.storeActive = data.data;
      this.sourceObj = data.data;
      const info = JSON.parse(localStorage.getItem('userInfo'));
      localStorage.setItem('userInfo', JSON.stringify({ ...info, store: data.data }));
    },
    changeDefaultStore (storeId) {
      settingServices.changeDefaultStore({ default_store_id: storeId }).then(() => {
        this.$router.push({ name: 'indexDash' }).catch(() => { });
        this.getInfo();
      });
    }
  },
  watch: {
    sourceObj: {
      handler (val) {
        this.market = { ...val };
        for (const key in this.images) {
          this.images[ key ].image = this.market[ key ];
        }
      },
    },
    deep: true,
    immediate: true,
  },
};
